export function articles() {
  return [
    {
      id: "HowToMakeFriendsWithChatGpt",
      authorId: "AlisonKing",
      author: "Alison King",
      posted: "May 1, 2023",
      heading: "How To Make Friends With ChatGPT",
      thumbnail: "https://media.graphassets.com/VAXRIDbUTRS9dDCBH9kJ",
      route: "/articles/how-to-make-friends-with-chat-gpt"
    },
    {
      id: "IWrote1000WordsFor30DaysAndHereIsWhatHappened",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Mar 21, 2023",
      heading: "I Wrote 1,000 Words for 30 Days and Here’s What Happened",
      thumbnail: "https://media.graphassets.com/CcXNX5RZQuSk4yu4Mcvi",
      route: "/articles/i-wrote-1000-words-for-30-days-and-here-is-what-happened"
    },
    {
      id: "WhenShouldICallMyselfAWriter",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Feb 17, 2023",
      heading: "When Should I Call Myself a Writer?",
      thumbnail: "https://media.graphassets.com/davdMHswQ1qH15LNrWiq",
      route: "/articles/when-should-i-call-myself-a-writer"
    },
    {
      id: "WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Jan 27, 2023",
      heading: "What Should I Write About? Five Ways To Find New Writing Ideas",
      thumbnail: "https://media.graphassets.com/xoOMWOhTtqyua7ofuORK",
      route: "/articles/what-should-i-write-about-five-ways-to-find-new-writing-ideas"
    },
    {
      id: "FiveTraitsOfAnUnforgettableScene",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Sep 17, 2021",
      heading: "Five Traits of an Unforgettable Scene",
      description:
        "Mastering the art of scene writing is critical to maintaining your reader’s attention throughout your work. Here are five traits of an unforgettable scene to keep your reader hooked.",
      thumbnail: "https://media.graphassets.com/6HMxuHQ1a3Uf1GywZf7Q",
      route: "/articles/five-traits-of-an-unforgettable-scene"
    },
    {
      id: "FiveWaysToCreateNewSettingsInYourWriting",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Jun 28, 2021",
      heading: "Five Ways to Create New Settings in Your Writing",
      thumbnail: "https://media.graphassets.com/7T1zLZpZRayYBEDViK1y",
      route: "/articles/five-ways-to-create-new-settings-in-your-writing"
    },
    {
      id: "SubmissionsTheHarshRealityAndHowToImproveYourOdds",
      authorId: "TheaPueschel",
      author: "Thea Pueschel",
      posted: "Jun 3, 2021",
      heading: "Submissions: The Harsh Reality and How to Improve Your Odds",
      thumbnail: "https://media.graphassets.com/UyrxZEkeR1WZgOrXzhZg",
      route: "/articles/submissions-the-harsh-reality-and-how-to-improve-your-odds"
    },
    {
      id: "FiveStepsToImprovePacingInYourStory",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Feb 11, 2021",
      heading: "Five Steps To Improve Pacing in Your Story",
      thumbnail: "https://media.graphassets.com/TUKtZIJFSAO2JnZJAygg",
      route: "/articles/five-steps-to-improve-pacing-in-your-story"
    },
    {
      id: "TheElementsOfStorytellingForNovelists",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Jan 19, 2021",
      heading: "The Elements of Storytelling for Novelists",
      thumbnail: "https://media.graphassets.com/zufGad7T8aAa4GCYdNkG",
      route: "/articles/the-elements-of-storytelling-for-novelists"
    },
    {
      id: "HowToKeepYourWritingOnTrack",
      authorId: "RennieSaunders",
      author: "Rennie Saunders",
      posted: "Jan 13, 2021",
      heading: "How to Keep Your Writing on Track",
      thumbnail: "https://media.graphassets.com/7XiiEap0RZGVkFg1PuG9",
      route: "/articles/how-to-keep-your-writing-on-track"
    },
    {
      id: "HowToWorkWithAnIllustrator",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "Dec 16, 2020",
      heading: "How to Work With an Illustrator",
      description:
        "As a writer, you get to see the images you’ve been imagining in your mind take shape in the physical world. Often, collaborating with another artist can create an explosion of creativity as they ask questions you wouldn’t think to ask yourself.",
      thumbnail: "https://media.graphassets.com/52bgJg6GQCq2aWL0gRAl",
      route: "/articles/how-to-work-with-an-illustrator"
    },
    {
      id: "ShouldYouSelfPublishOrTraditionallyPublish",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Dec 8, 2020",
      heading: "Should You Self-Publish or Traditionally Publish?",
      thumbnail: "https://media.graphassets.com/f8dkHOoTu6Zg6SJDzHtt",
      route: "/articles/should-you-self-publish-or-traditionally-publish"
    },
    {
      id: "HowToSelfEditInSixPainlessSteps",
      authorId: "AlisonKing",
      author: "Alison King",
      posted: "Nov 20, 2020",
      heading: "How to Self-Edit in Six Painless Steps",
      thumbnail: "https://media.graphassets.com/mRM9YE1SfGu4PcvIYPJe",
      route: "/articles/how-to-self-edit-in-six-painless-steps"
    },
    {
      id: "StayingCreativeWhileTheWorldIsBurning",
      authorId: "RennieSaunders",
      author: "Rennie Saunders",
      posted: "Nov 18, 2020",
      heading: "Staying Creative While the World is Burning",
      thumbnail: "https://media.graphassets.com/gsUuoWGuQ4WuK1trsOwm",
      route: "/articles/staying-creative-while-the-world-is-burning"
    },
    {
      id: "MakeWritingAHabitNotAChoice",
      authorId: "ClaireSplan",
      author: "Claire Splan",
      posted: "Nov 18, 2020",
      heading: "Make Writing a Habit, Not a Choice",
      thumbnail: "https://media.graphassets.com/1niB9F6RiacrviXT6YL6",
      route: "/articles/make-writing-a-habit-not-a-choice"
    },
    {
      id: "HowToFindALiteraryAgent",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Oct 26, 2020",
      heading: "How to Find a Literary Agent",
      thumbnail: "https://media.graphassets.com/10U99ylGTZWdvurzhWWH",
      route: "/articles/how-to-find-a-literary-agent"
    },
    {
      id: "BetweenEditingAndSubmission",
      authorId: "JulieHoward",
      author: "Julie Howard",
      posted: "Sep 29, 2020",
      heading: "Between Editing and Submission",
      thumbnail: "https://media.graphassets.com/1EfTHruJQni4sltSfwFx",
      route: "/articles/between-editing-and-submission"
    },
    {
      id: "HowToEditTheFirstDraftOfYourNovel",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Sep 21, 2020",
      heading: "How to Edit the First Draft of Your Novel",
      thumbnail: "https://media.graphassets.com/ihAvZx2dThCPENdph3w0",
      route: "/articles/how-to-edit-the-first-draft-of-your-novel"
    },
    {
      id: "HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite",
      authorId: "CristianAluas",
      author: "Cristian Aluas",
      posted: "Aug 31, 2020",
      heading: "How I Completed My First Novel With the Help of Shut Up & Write",
      thumbnail: "https://media.graphassets.com/yeIx6CnQtWdVmgjXor9n",
      route: "/articles/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write"
    },
    {
      id: "FindingAndWorkingWithABetaReader",
      authorId: "AlisonKing",
      author: "Alison King",
      posted: "Aug 18, 2020",
      heading: "Finding and Working With a Beta Reader",
      thumbnail: "https://media.graphassets.com/CL2dY6vUQKaynJFNGbwZ",
      route: "/articles/finding-and-working-with-a-beta-reader"
    },
    {
      id: "HowToGetABookCoverYoureProudOf",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Aug 14, 2020",
      heading: "How to Get a Book Cover You’re Proud Of",
      thumbnail: "https://media.graphassets.com/94sZSFMLQ6f0EpaLiqd7",
      route: "/articles/how-to-get-a-book-cover-youre-proud-of"
    },
    {
      id: "TricksForWritingUnderPressure",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "Jul 15, 2020",
      heading: "Tricks for Writing Under Pressure",
      thumbnail: "https://media.graphassets.com/VTizxlsSReUVjl7Gk2TQ",
      route: "/articles/tricks-for-writing-under-pressure"
    },
    {
      id: "HowToGetFeedbackOnYourWriting",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Jul 6, 2020",
      heading: "How to Get Feedback on Your Writing",
      thumbnail: "https://media.graphassets.com/M2fgScTfKf5AtMF5EfKQ",
      route: "/articles/how-to-get-feedback-on-your-writing"
    },
    {
      id: "FourEssentialStepsToDevelopingYourWritingPractice",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "May 27, 2020",
      heading: "Four Essential Steps to Developing Your Writing Practice",
      thumbnail: "https://media.graphassets.com/C6bDRIexSaO42vzDI54B",
      route: "/articles/four-essential-steps-to-developing-your-writing-practice"
    },
    {
      id: "SevenStrategiesToGetYouWritingAgain",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "May 26, 2020",
      heading: "Seven Strategies to Get You Writing Again",
      thumbnail: "https://media.graphassets.com/zlNMrmZbQa6B7e50KAc4",
      route: "/articles/seven-strategies-to-get-you-writing-again"
    },
    {
      id: "TheWritersGuideToCopyright",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Apr 6, 2020",
      heading: "The Writer’s Guide to Copyright",
      thumbnail: "https://media.graphassets.com/euGEkbf8TqyvJM3tGdN6",
      route: "/articles/the-writers-guide-to-copyright"
    },
    {
      id: "TenBestPracticesForWritingFromHome",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Mar 20, 2020",
      heading: "10 Best Practices for Writing From Home",
      thumbnail: "https://media.graphassets.com/JxNUeqiWRmqJIRXi2u3g",
      route: "/articles/10-best-practices-for-writing-from-home"
    },
    {
      id: "TheWritersGuideToEditors",
      authorId: "AlisonKing",
      author: "Alison King",
      posted: "Feb 21, 2020",
      heading: "The Writer’s Guide to Editors",
      thumbnail: "https://media.graphassets.com/tDfqmZuRS12a28n9TKlY",
      route: "/articles/the-writers-guide-to-editors"
    },
    {
      id: "CreatingYourWritingEnvironment",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "Jan 29, 2020",
      heading: "Creating Your Writing Environment",
      thumbnail: "https://media.graphassets.com/oYDdPAOQ2iQyHRWdXFFh",
      route: "/articles/creating-your-writing-environment"
    },
    {
      id: "FiveWaysToSilenceYourInnerCriticWhileDrafting",
      authorId: "LiraSamanta",
      author: "Lira Samanta",
      posted: "Dec 1, 2019",
      heading: "Five Ways to Silence Your Inner Critic While Drafting",
      thumbnail: "https://media.graphassets.com/Yl1jWzlXSZSpbKXAQGJW",
      route: "/articles/five-ways-to-silence-your-inner-critic-while-drafting"
    },
    {
      id: "ShutUpAndWritesNanowrimoSurvivalGuide",
      authorId: "JeremyThweatt",
      author: "Jeremy Thweatt",
      posted: "Nov 1, 2019",
      heading: "Shut Up & Write!'s NaNoWriMo Survival Guide",
      thumbnail: "https://media.graphassets.com/8wUE3ccFS5ikuMmVlhoZ",
      route: "/articles/shut-up-and-writes-nanowrimo-survival-guide"
    },
    {
      id: "FourEasyStepsToAWritingPlan",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      posted: "Feb 12, 2018",
      heading: "Four Easy Steps to a Writing Plan",
      thumbnail: "https://media.graphassets.com/7r6gjq5YSOp2o06tvRLG",
      route: "/articles/4-easy-steps-to-a-writing-plan"
    },
    {
      id: "FiveSimpleHabitsToBoostYourWriting",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      posted: "Jan 4, 2018",
      heading: "Five Simple Habits To Boost Your Writing",
      thumbnail: "https://media.graphassets.com/sY1pwJpRtuuhYk1YqNIz",
      route: "/articles/5-simple-habits-to-boost-your-writing"
    },
    {
      id: "ItsOkayToBeEditedReally",
      authorId: "JulieHoward",
      author: "Julie Howard",
      posted: "Sep 1, 2017",
      heading: "It’s Okay To Be Edited. Really.",
      thumbnail: "https://media.graphassets.com/XwWARFTZSxGAJaui6C2d",
      route: "/articles/its-okay-to-be-edited-really"
    },
    {
      id: "AttendingTheMuseOrYourWritingDiscipline",
      authorId: "RennieSaunders",
      author: "Rennie Saunders",
      posted: "Feb 28, 2017",
      heading: "“Attending the Muse,” or Your Writing Discipline",
      thumbnail: "https://media.graphassets.com/but9bLTNRxG94rux8XaK",
      route: "/articles/attending-the-muse-or-your-writing-discipline"
    },
    {
      id: "FiveGeekyHobbiesForWritersThatArentReading",
      authorId: "KellieDoherty",
      author: "Kellie Doherty",
      posted: "Jan 10, 2017",
      heading: "Five Geeky Hobbies for Writers (That Aren’t Reading!)",
      thumbnail: "https://media.graphassets.com/wfUJKBoSVKVVmVYyyXuw",
      route: "/articles/five-geeky-hobbies-for-writers-that-arent-reading"
    },
    {
      id: "AfterTheFirstDraftProTipsForEditingYourWork",
      authorId: "ClaireSplan",
      author: "Claire Splan",
      posted: "May 18, 2016",
      heading: "After the First Draft, Pro Tips for Editing Your Work",
      thumbnail: "https://media.graphassets.com/34HINKLmRnKSJwvUvbV7",
      route: "/articles/after-the-first-draft-pro-tips-for-editing-your-work"
    },
    {
      id: "BeginningTipsForWritersWorkingStylesAndWip",
      authorId: "CindyPowers",
      author: "Cindy Powers",
      posted: "Apr 16, 2016",
      heading: "Beginning Tips for Writers: Working Styles and WIP",
      thumbnail: "https://media.graphassets.com/0yhuMFhCSYm0irv27Grp",
      route: "/articles/beginning-tips-for-writers-working-styles-and-wip"
    },
    {
      id: "PerfectionismDistractsYouFromWritingPerfectly",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      posted: "Apr 5, 2016",
      heading: "Perfectionism Distracts You From Writing Perfectly",
      thumbnail: "https://media.graphassets.com/VMaTmI4KR5Glk6tHuqyS",
      route: "/articles/perfectionism-distracts-you-from-writing-perfectly"
    },
    {
      id: "ConquerProcrastinationAndGetWriting",
      authorId: "CatherineEllsworthon",
      author: "Catherine Ellsworthon",
      posted: "Apr 3, 2016",
      heading: "Conquer Procrastination and Get Writing",
      thumbnail: "https://media.graphassets.com/rwpW4IkMQReKV9MY2Nmp",
      route: "/articles/conquer-procrastination-and-get-writing"
    },
    {
      id: "BeginningTipsForWritersTheBasics",
      authorId: "CindyPowers",
      author: "Cindy Powers",
      posted: "Feb 13, 2016",
      heading: "Beginning Tips for Writers: The Basics",
      thumbnail: "https://media.graphassets.com/lZxWaS8Ra6zcX4KcZUdQ",
      route: "/articles/beginning-tips-for-writers-the-basics"
    }
  ];
}

export function authors() {
  return [
    {
      id: "AlisonKing",
      name: "Alison King",
      bio:
        "Shut Up & Write's Product Manager, Alison King, is also the team's in-house editor. Alison is a writer and lifelong music geek, and can be found in Berkeley, CA, or at @alison_king on Twitter.",
      articles: ["HowToMakeFriendsWithChatGpt", "HowToSelfEditInSixPainlessSteps", "FindingAndWorkingWithABetaReader", "TheWritersGuideToEditors"]
    },
    {
      id: "CatherineEllsworthon",
      name: "Catherine Ellsworthon",
      bio: "",
      articles: ["FourEasyStepsToAWritingPlan", "FiveSimpleHabitsToBoostYourWriting", "PerfectionismDistractsYouFromWritingPerfectly", "ConquerProcrastinationAndGetWriting"]
    },
    {
      id: "CindyPowers",
      name: "Cindy Powers",
      bio:
        "Writer of male/male romance stories and fan fiction. Find me Twitter and Tumblr hanging out with my friends, working on my stories, blogging, or immersed in learning something new. Be yourself, live your passion.",
      articles: ["BeginningTipsForWritersTheBasics", "BeginningTipsForWritersWorkingStylesAndWip"]
    },
    {
      id: "ClaireSplan",
      name: "Claire Splan",
      bio:
        "Claire Splan writes fiction and nonfiction and runs a weekly Shut Up & Write session in Alameda. She has been a professional editor for what feels like a thousand years and has worked on literally hundreds of books on a wide range of topics. Her recently published book <a href='https://www.amazon.com/Not-So-Hot-Tomatoes-Growing-Delicious-Climates/dp/B093M54Z6G/ref=sr_1_1?dchild=1&keywords=not+so+hot+tomatoes&qid=1623429408&sr=8-1'>Not So Hot Tomatoes: Growing Tomatoes in Cooler Climates</a> is now available on Amazon.",
      articles: ["AfterTheFirstDraftProTipsForEditingYourWork", "MakeWritingAHabitNotAChoice"]
    },
    {
      id: "CristianAluas",
      name: "Cristian Aluas",
      bio:
        "Shut Up & Write member Cristian S. Aluas is an artist and writer living in Manhattan. He's the author of a new book titled <a href='https://www.amazon.com/ITS-LIVING-Surviving-Freelancer-Professionals/dp/1709485345'>IT'S A LIVING: Surviving as a Freelancer in the 21st Century, The Ultimate Guide to Success for Artists and Creative Professionals</a>. Readers can follow Cristian on Instagram at @masterpieceartschool.",
      articles: ["HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite"]
    },
    {
      id: "JeremyThweatt",
      name: "Jeremy Thweatt",
      bio:
        "Jeremy manages the content department and loves engaging our members through educational blog posts about the craft of writing, while continually reading about all things writing-related in order to distill what he learns into content for Shut Up & Write. He is currently co-authoring a science fiction novel with Rennie.",
      articles: [
        "HowToWorkWithAnIllustrator",
        "TricksForWritingUnderPressure",
        "FourEssentialStepsToDevelopingYourWritingPractice",
        "SevenStrategiesToGetYouWritingAgain",
        "CreatingYourWritingEnvironment",
        "ShutUpAndWritesNanowrimoSurvivalGuide"
      ]
    },
    {
      id: "JulieHoward",
      name: "Julie Howard",
      bio:
        "Boise City Manager at Shut Up & Write! Julie Howard leads Shut Up & Write groups in Boise, Idaho. She is the author of <em>Crime and Paradise</em>, <em>Crime Times Two</em>, and <em>Spirited Quest</em>. She is currently on pins and needles waiting to see if her fourth book will be contracted by her publisher, The Wild Rose Press. More about her and her books are at www.juliemhoward.com or find her on Twitter at @_juliemhoward.",
      articles: ["BetweenEditingAndSubmission", "ItsOkayToBeEditedReally"]
    },
    {
      id: "KellieDoherty",
      name: "Kellie Doherty",
      bio:
        "Kellie Doherty is from Eagle River, Alaska, though she now resides in Portland, Oregon. Living in Alaska for a good portion of her life gave her an appreciation for nature, a hankering for a good cup of tea or cider, and a passion for all things below zero. During those dark winter nights, she kept herself busy by writing stories and creating characters. After spending time in already created universes writing fan fiction, she decided to build her own. Her first book <a href='https://www.amazon.com/Finding-Hekate-Cicatrix-Duology-1/dp/1942976062'>Finding Hekate</a> was published by Desert Palm Press in April 2016, and she's currently working on the sequel titled Losing Hold. Her work has also been featured in Flight (Mischief Corner Books, 2016), 49 Writers Alaska Shorts, Pathos Literary Magazine, the Chugiak Eagle River Star, F Magazine, and Alaska Women Speak.",
      articles: ["FiveGeekyHobbiesForWritersThatArentReading"]
    },
    {
      id: "LiraSamanta",
      name: "Lira Samanta",
      bio:
        "Lira Samanta is Data Analytics & Content Operations Manager at Shut Up & Write!. She writes poetry and literary & speculative fiction and is currently at work on her umpteenth novel.",
      articles: [
        "IWrote1000WordsFor30DaysAndHereIsWhatHappened",
        "WhenShouldICallMyselfAWriter",
        "WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas",
        "FiveTraitsOfAnUnforgettableScene",
        "FiveWaysToCreateNewSettingsInYourWriting",
        "FiveStepsToImprovePacingInYourStory",
        "TheElementsOfStorytellingForNovelists",
        "ShouldYouSelfPublishOrTraditionallyPublish",
        "HowToFindALiteraryAgent",
        "HowToEditTheFirstDraftOfYourNovel",
        "HowToGetABookCoverYoureProudOf",
        "HowToGetFeedbackOnYourWriting",
        "TheWritersGuideToCopyright",
        "TenBestPracticesForWritingFromHome",
        "FiveWaysToSilenceYourInnerCriticWhileDrafting"
      ]
    },
    {
      id: "RennieSaunders",
      name: "Rennie Saunders",
      bio:
        "Rennie founded Shut Up & Write! in 2007 following a desire to meet fellow writers while working on a series of science fiction novels. Rennie spends an inordinate amount of time reading Wikipedia and Discover Magazine articles as research for his science fiction writing, practices Indonesian martial arts and cooks wholesome dinners for his family. His novella, <a href='https://www.amazon.com/gp/product/B01B7LKPSW/ref=dbs_a_def_rwt_hsch_vapi_tkin_p1_i0'>Pale Angel</a>, is available on Amazon and The Proteus Knife, a novel, will be released soon.",
      articles: ["HowToKeepYourWritingOnTrack", "StayingCreativeWhileTheWorldIsBurning", "AttendingTheMuseOrYourWritingDiscipline"]
    },
    {
      id: "TheaPueschel",
      name: "Thea Pueschel",
      bio:
        "Thea Pueschel is a writer and multimedia artist in Southern California. Thea writes ESL readers to pay the bills and literary fiction to feed the soul. Thea won the TAEM Flash Fiction Summer 2020 Contest for the pandemic based satire “Safer at Home.”",
      articles: ["SubmissionsTheHarshRealityAndHowToImproveYourOdds"]
    }
  ];
}
