<script>
import { SuawMainContent, SuawSection, SuawCarouselColumns, SuawCarouselRows, SuawSubheaderTwo } from "@suaw/suaw-component-library";
import * as db from "../data.js";

export default {
  name: "ArticleDiscovery",
  metaInfo: {
    title: "Articles - ",
    meta: [
      { vmid: "description", name: "description", content: "Feel inspired, learn something new, or find some help. Our articles have something to keep everyone writing." }
    ]
  },
  components: {
    SuawMainContent,
    SuawSection,
    SuawCarouselColumns,
    SuawCarouselRows,
    SuawSubheaderTwo
  },
  data() {
    return {
      cards: db.articles()
    };
  },
  computed: {
    featuredArticles() {
      return this.cards.filter(card => card.description).map(card => ({ ...card, cardType: "CardArticleExpanded" }));
    },
    recentArticles() {
      return this.cards.map(card => ({ ...card, cardType: "CardArticle" }));
    }
  },
  methods: {
    onClicked(val) {
      this.$router.push(val.route);
    }
  }
};
</script>

<template>
  <SuawMainContent :show-gradient="true">
    <SuawSubheaderTwo title="Articles" description="Feel inspired, learn something new, or find some help. Our articles have something to keep everyone writing." />
    <SuawSection>
      <SuawCarouselColumns carousel-heading="Featured Articles" card-type="CardArticleExpanded" :cards="featuredArticles" :cards-per-page="1" @click="onClicked" />
    </SuawSection>
    <SuawSection>
      <SuawCarouselRows carousel-heading="Recent Articles" card-type="CardArticle" :cards="recentArticles" :cards-per-page="16" @click="onClicked" />
    </SuawSection>
  </SuawMainContent>
</template>
